export const installments = [
  { id: 1, title: "1x", value: 1 },
  { id: 2, title: "2x", value: 2 },
  { id: 3, title: "3x", value: 3 },
  { id: 4, title: "4x", value: 4 },
  { id: 5, title: "5x", value: 5 },
  { id: 6, title: "6x", value: 6 },
  { id: 7, title: "7x", value: 7 },
  { id: 8, title: "8x", value: 8 },
  { id: 9, title: "9x", value: 9 },
  { id: 10, title: "10x", value: 10 },
  { id: 11, title: "11x", value: 11 },
  { id: 12, title: "12x", value: 12 },
  { id: 13, title: "13x", value: 13 },
  { id: 14, title: "14x", value: 14 },
  { id: 15, title: "15x", value: 15 },
  { id: 16, title: "16x", value: 16 },
  { id: 17, title: "17x", value: 17 },
  { id: 18, title: "18x", value: 18 },
  { id: 19, title: "19x", value: 19 },
  { id: 20, title: "20x", value: 20 },
  { id: 21, title: "21x", value: 21 },
  { id: 22, title: "22x", value: 22 },
  { id: 23, title: "23x", value: 23 },
  { id: 24, title: "24x", value: 24 },
  
];

export const installmentsContasAPagar = [
  { id: 1, title: "Indefinido ∞", value: 20},
  { id: 2, title: "2x", value: 2 },
  { id: 3, title: "3x", value: 3 },
  { id: 4, title: "4x", value: 4 },
  { id: 5, title: "5x", value: 5 },
  { id: 6, title: "6x", value: 6 },
  { id: 7, title: "7x", value: 7 },
  { id: 8, title: "8x", value: 8 },
  { id: 9, title: "9x", value: 9 },
  { id: 10, title: "10x", value: 10 },
  { id: 11, title: "11x", value: 11 },
  { id: 12, title: "12x", value: 12 },
];
